import React, { useEffect, useCallback, useState } from 'react';
import { navigate } from 'gatsby';
import { scheduleTimeService } from '../../../services/scheduleTime';
import { assetsService } from '../../../services/general';
import { message } from 'antd';
import moment from 'moment';

const useFormTabs = () => {
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const [loading, setLoading] = useState(false);
  const [branchOffices, setBranchOffices] = useState([]);
  const [reservationQuery, setReservationQuery] = useState({ branchOffice: null, patientsQty: null });
  const [selectedDate, setSelectedDate] = useState(moment());
  const [disabledDates, setDisabledDates] = useState([]);
  const sundayNumber = 6;


  const fetchBranchOffices = useCallback(async () => {
    try {
      setLoading(true);
      const response = await scheduleTimeService.branchOffices({ countryCode });

      if (response) {
        setBranchOffices(response);
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error fetching branch offices', error);
      message.error({
        content: 'Error al obtener sucursales',
        style: {
          marginTop: '40vh',
        },
      });
    } finally {
      setLoading(false);
    }
  }, [countryCode]);

  const onFinish = (values) => {
    let params = {
      branchOfficeId: values.branchOffice,
      patientsQty: values.patientsQty,
      date: moment(values.date).format('YYYY-MM-DD'),
    };

    navigate(`/app/reservar?branchOfficeId=${params.branchOfficeId}&patientsQty=${params.patientsQty}&date=${params.date}`);
  };

  useEffect(() => {
    fetchBranchOffices();
  }, [fetchBranchOffices]);


  const fetchDisabledDates = useCallback(async (params) => {
    try {
      const response = await assetsService.getDisabledDates(params);

      if (response && response.disabled_dates) {
        setDisabledDates(response.disabled_dates.map((date) => moment(date)));
      } else {
        throw new Error(response);
      }
    } catch (error) {
      console.error('Error en Fetch Disabled Dates: ', error);
      message.error({
        content: `Error al consultar disponibilidad del Calendario: ${error}`,
        style: {
          marginTop: '40vh',
        },
      });
    }
  }, []);


  useEffect(() => {
    if (reservationQuery.branchOffice && reservationQuery.patientsQty) {
      const params = {
        branch_office_id: reservationQuery.branchOffice,
        patients_qty: reservationQuery.patientsQty,
        current_month: selectedDate.month() + 1,
      };

      // fetchDisabledDates(params);
    }
  }, [reservationQuery, selectedDate]);

  const handleCalendarAvailability = (current) => {
    if (!selectedDate) {
      return false;
    }

    const currentMonth = moment().month() + 1;
    const currentYear = moment().year();
    const pickedMonth = selectedDate.month() + 1;
    const pickedYear = selectedDate.year();
    const firstDayOfPickedMonth = moment(selectedDate).startOf('month');
    const lastDayOfPickedMonth = moment(selectedDate).endOf('month');

    if (
      (pickedYear < currentYear) ||
      (pickedYear === currentYear && pickedMonth < currentMonth) ||
      (current.weekday() === sundayNumber)
    ) {
      return true;
    } else if (disabledDates.length > 0 && disabledDates.some((date) => date.isSame(current, 'day'))) {
      return true;
    } else if (pickedMonth === currentMonth && current.isBefore(moment().startOf('day'))) {
      return true;
    } else if (current.isBefore(firstDayOfPickedMonth) || current.isAfter(lastDayOfPickedMonth)) {
      return true;
    } else {
      return false;
    }
  };

  return {
    loading,
    onFinish,
    branchOffices,
    selectedDate,
    setSelectedDate,
    reservationQuery,
    setReservationQuery,
    handleCalendarAvailability,
  }
};

export default useFormTabs;
